<template>
  <v-card>
    <!--    TODO: reuse BaseTabsDialog-->
    <v-toolbar flat :color="toolbarColor" :dark="toolbarDark">
      <!-- toolbar avatar -->
      <v-avatar v-if="iconName">
        <v-icon :color="toolbarIconColor">{{ iconName }}</v-icon>
      </v-avatar>

      <!-- toolbar title -->
      <v-toolbar-title :class="toolbarTitleClass">
        <slot>User Settings</slot>
      </v-toolbar-title>

      <!-- toolbar gradient -->
      <template v-slot:img="{ props }">
        <v-img
          v-if="canApplyGradient"
          v-bind="props"
          :gradient="toolbarGradient"
        ></v-img>
      </template>

      <!-- Tabs -->
      <template v-slot:extension>
        <v-tabs v-model="tabs" show-arrows fixed-tabs :color="tabsColor">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>App Bar</v-tab>
          <v-tab>Side Bar</v-tab>
          <v-tab>Records</v-tab>
          <v-tab>Buttons</v-tab>
          <v-tab>Features</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <!-- Tabs' Content -->
    <v-card-text>
      <v-tabs-items v-model="tabs">
        <!-- Application Bar -->
        <v-tab-item>
          <v-card flat>
            <v-list>
              <!-- Use Application Tabs -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionAppTabs"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Use Application Tabs</v-list-item-title>
              </v-list-item>

              <!-- Collapse On Scroll -->
              <!-- Puts the app-bar into a collapsed state when scrolling. -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionCollapseOnScroll"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Collapse On Scroll</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- Sidebar Bar (Left Drawer) -->
        <v-tab-item>
          <v-card flat>
            <v-list>
              <!-- Sidebar Image -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionSidebarImage"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Sidebar Image</v-list-item-title>
              </v-list-item>

              <!-- Sidebar Image -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionEnableMiniDrawer"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Enable Mini Sidebar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- Record list -->
        <v-tab-item>
          <v-card flat>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-select
                    v-model="optionRecordsFetchCount"
                    label="Fetch Records"
                    :prepend-icon="iconFetchRecords"
                    :items="displayRecordOptions"
                  ></v-select>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- Command Buttons -->
        <v-tab-item>
          <v-card flat>
            <v-list>
              <!-- Text Style -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionButtonTextStyle"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Text Style</v-list-item-title>
              </v-list-item>

              <!-- Outlined -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionButtonOutlined"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Outlined</v-list-item-title>
              </v-list-item>

              <!-- rounded -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="optionButtonRounded"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Rounded</v-list-item-title>
              </v-list-item>

              <!-- elevation -->
              <v-list-item>
                <v-slider
                  max="24"
                  min="0"
                  label="Elevation"
                  v-model="optionButtonElevation"
                ></v-slider>
                <!--                <v-list-item-title>elevation</v-list-item-title>-->
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- Questys features -->
        <v-tab-item>
          <v-card flat>
            <v-list>
              <!-- Search -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableSearch"
                    :disabled="disabledOptionEnableSearch"
                  ></v-switch>
                </v-list-item-action>

                <v-list-item-title>{{ titleSearch }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.Execute)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.Execute) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- Folder -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableFolder"
                    :disabled="disabledOptionEnableFolder"
                  ></v-switch>
                </v-list-item-action>

                <v-list-item-title>{{ titleFolder }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.Execute)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.Execute) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- Category -->
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableCategory"
                    :disabled="disabledOptionEnableCategory"
                  ></v-switch>
                </v-list-item-action>

                <v-list-item-title>{{ titleCategory }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.Execute)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.Execute) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- My Tasks -->
              <v-list-item v-show="!isPublicAccess">
                <!-- switch feature -->
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableWorkflowMyTasks"
                    :disabled="!isAvailableFeature(featureName.WFx)"
                  ></v-switch>
                </v-list-item-action>

                <!-- feature title  -->
                <v-list-item-title>{{ titleMyTasks }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.WFx)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.WFx) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- Projects -->
              <v-list-item v-show="visibleProjects">
                <!-- switch feature -->
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableWorkflowProjects"
                    :disabled="!enabledProjects"
                  ></v-switch>
                </v-list-item-action>

                <!-- feature title  -->
                <v-list-item-title>{{ titleProjects }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="
                      iconFeature(featureName.WFx, allowedAccessWFProjects)
                    "
                  >
                    <template v-slot:default>
                      {{
                        featureStatusText(
                          featureName.WFx,
                          allowedAccessWFProjects
                        )
                      }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- Meetings -->
              <v-list-item v-show="!isPublicAccess">
                <!-- switch feature -->
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableAgendaMeetings"
                    :disabled="!isAvailableFeature(featureName.LSx)"
                  ></v-switch>
                </v-list-item-action>

                <!-- feature title  -->
                <v-list-item-title>{{ titleAgenda }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.LSx)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.LSx) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- Web Scan -->
              <v-list-item v-show="!isPublicAccess">
                <!-- switch feature -->
                <v-list-item-action>
                  <v-switch
                    v-model="optionEnableWebScan"
                    :disabled="true"
                  ></v-switch>
                </v-list-item-action>

                <!-- feature title  -->
                <v-list-item-title>{{ titleWebScan }}</v-list-item-title>

                <!-- feature availability indicator (feature tool-tip icon) -->
                <v-list-item-action>
                  <base-tooltip-icon
                    top
                    :icon-name="iconFeature(featureName.WebScan)"
                  >
                    <template v-slot:default>
                      {{ featureStatusText(featureName.WebScan) }}
                    </template>
                  </base-tooltip-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <base-button @click="close">{{ commandLabelClose }}</base-button>
    </v-card-actions>
  </v-card>
</template>

<script>
// design
import {
  iconCheck,
  iconClear,
  iconConfigure,
  iconFolderRefresh
} from "@/design/icon/iconConst";
import { Text } from "@/design/text/Text";

// model
import { commandNames } from "@/model/common/commands/commandModel";
import { eventNames } from "@/model/common/events/eventConst";
import { moduleTitles } from "@/services/solution/solutionService";
import { featureName } from "@/model/features/featuresModel";

// services
import { indigoGradient } from "@/design/gradients/gradientConst";

// Mixins
import { userSettingsMixin } from "@/mixins/shared/base/settings/userSettingsMixin";
import { dialogToolbarSettingsMixin } from "@/mixins/shared/base/settings/dialogToolbarSettingsMixin";
import { moduleNames } from "@/services/solution/solutionService";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "UserSettings",
  mixins: [
    userSettingsMixin,
    dialogToolbarSettingsMixin,
    publicAccessMixin,
    userMixin
  ],
  components: {
    BaseButton: () => import("@/components/shared/base/BaseButton"),
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  data() {
    return {
      visibleMenu: false,
      tabs: 0,
      commandLabelClose: commandNames.close,
      iconName: iconConfigure,
      toolbarGradient: indigoGradient,
      iconFeatureNotAvailable: iconClear,
      featureName: featureName,
      displayRecordOptions: [10, 20, 50, 100, 200, 500],
      iconFetchRecords: iconFolderRefresh
    };
  },
  computed: {
    titleSearch: () => moduleTitles.Search,
    titleFolder() {
      return moduleTitles.Folder;
    },
    titleCategory() {
      return moduleTitles.Category;
    },
    titleMyTasks() {
      return moduleTitles.Tasks;
    },
    titleProjects() {
      return moduleTitles.Projects;
    },
    titleAgenda() {
      return moduleTitles.Agenda;
    },
    titleWebScan() {
      return "Web Scan";
    },
    toolbarColor() {
      return this.dialogToolbarStyle ? this.dialogTitleColorClass : undefined;
    },
    toolbarIconColor() {
      return this.dialogToolbarStyle ? undefined : this.dialogTitleColorClass;
    },
    toolbarTitleClass() {
      return this.dialogToolbarStyle
        ? undefined
        : new Text(this.mdDialogTitleColor).getClassText();
    },
    toolbarDark() {
      return this.dialogToolbarStyle;
    },
    tabsColor() {
      return this.dialogToolbarStyle ? undefined : this.dialogTitleColorClass;
    },
    canApplyGradient() {
      return this.dialogToolbarStyle;
    },
    optionAppTabs: {
      get() {
        return this.usesAppTabs;
      },
      set(value) {
        this.setUsesAppTabs(value);
      }
    },
    optionCollapseOnScroll: {
      get() {
        return this.appBarCollapseOnScroll;
      },
      set(value) {
        this.setAppBarCollapseOnScroll(value);
      }
    },
    optionSidebarImage: {
      get() {
        return this.usesSidebarImage;
      },
      set(value) {
        this.setUsesSidebarImage(value);
      }
    },
    optionEnableMiniDrawer: {
      get() {
        return this.enableMiniDrawer;
      },
      set(value) {
        this.setEnableMiniDrawer(value);
      }
    },
    optionRecordsFetchCount: {
      get() {
        return this.recordsFetchCount;
      },
      set(value) {
        this.setRecordsFetchCount(value);
      }
    },
    //
    // Button
    //
    optionButtonTextStyle: {
      get() {
        return this.buttonTextStyle;
      },
      set(value) {
        this.setButtonTextStyle(value);
      }
    },
    optionButtonOutlined: {
      get() {
        return this.buttonOutlined;
      },
      set(value) {
        this.setButtonOutlined(value);
      }
    },
    optionButtonRounded: {
      get() {
        return this.buttonRounded;
      },
      set(value) {
        this.setButtonRounded(value);
      }
    },
    optionButtonElevation: {
      get() {
        return this.buttonElevation;
      },
      set(value) {
        this.setButtonElevation(value);
      }
    },
    //
    // Features
    //
    optionEnableFolder: {
      get() {
        return true;
      }
    },
    disabledOptionEnableFolder() {
      return true;
    },
    optionEnableCategory: {
      get() {
        return this.enableCategoryModule;
      },
      set(value) {
        this.setEnableModuleCategory(value);
      }
    },
    disabledOptionEnableCategory() {
      return false;
    },
    optionEnableSearch: {
      get() {
        return this.enableSearchModule;
      },
      set(value) {
        this.setEnableModuleSearch(value);
      }
    },
    disabledOptionEnableSearch() {
      return false;
    },
    optionEnableWorkflowMyTasks: {
      get() {
        return this.isLicensedModule(moduleNames.Tasks)
          ? this.enableTasksModule
          : false;
      },
      set(value) {
        this.setEnableModuleTasks(value);
      }
    },
    disabledOptionWorkflowMyTasks() {
      return !this.isLicensedModule(moduleNames.Tasks);
    },
    optionEnableWorkflowProjects: {
      get() {
        return this.isLicensedModule(moduleNames.Projects)
          ? this.enableProjectsModule
          : false;
      },
      set(value) {
        this.setEnableModuleProjects(value);
      }
    },
    disabledOptionWorkflowProjects() {
      return !this.isLicensedModule(moduleNames.Projects);
    },
    optionEnableAgendaMeetings: {
      get() {
        return this.isLicensedModule(moduleNames.Agenda)
          ? this.enableAgendaModule
          : false;
      },
      set(value) {
        this.setEnableModuleAgenda(value);
      }
    },
    disabledOptionAgendaMeetings() {
      return !this.isLicensedModule(moduleNames.Agenda);
    },

    /**
     * ensure Web Scan is available,
     * since the Web Scan feature is built into the cost of Access to the customer
     * it is supported only on windows, not on any mobile devices
     * see: store\user
     */
    optionEnableWebScan: {
      get() {
        return this.enableWebScan;
      },
      set(value) {
        console.log(`optionEnableWebScan value:`, value);
      }
    },

    /**
     * visible Projects
     * @return {boolean} true if visible Projects
     */
    visibleProjects() {
      return !this.isPublicAccess && this.allowedAccessWFProjects;
    },

    /**
     * enabled Projects
     * @return {boolean} true if enabled Projects
     */
    enabledProjects() {
      return (
        !this.isPublicAccess &&
        !!this.isAvailableFeature(featureName.WFx) &&
        this.allowedAccessWFProjects
      );
    }
  },
  methods: {
    close() {
      // this.visibleMenu = false;
      this.$emit(eventNames.close, false);
    },
    save() {
      // this.visibleMenu = false;
      this.$emit(eventNames.close, true);
    },

    /**
     * feature Status Text
     * @param name feature name
     * @param {Boolean|boolean} allowed
     * @return {string}
     */
    featureStatusText(name, allowed = true) {
      const status = this.featureStatus(name)?.statusText;

      return allowed ? status : `Permission denied. ${status}`;
    },

    /**
     * Feature's icon name
     * @param name Feature name
     * @param {Boolean|boolean} allowed is allowed feature
     * @return {string} Feature's icon name
     */
    iconFeature(name, allowed = true) {
      if (!allowed) {
        return iconClear;
      }

      const status = this.featureStatus(name);
      return status.supported ?? false
        ? status.licenced && status.released
          ? iconCheck
          : iconClear
        : iconClear;
    }
  }
};
</script>
